<template>
  <div
    class="fixed z-4 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !isVisible,
    }"
  >
    <div class="p-2 border-1 rounded-lg w-3/4 h-3/4">
      <div class="h-3/5">
        <div class="p-4 bg-white rounded-lg w-full">
          <div class="flex justify-end px-2 py-1">
            <a @click="closeModal()" href="javascript:void(0)">
              <!-- <font-awesome-icon icon="fa-solid fa-xmark" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="20px"
              >
                <path
                  d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"
                />
              </svg>
            </a>
          </div>
          <div class="flex justify-end">
            <app-label :label-for="`table-search`" class="sr-only"
              >Search</app-label
            >
            <div class="relative mt-1">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <app-input
                v-model:value="searchForm.search"
                focus-class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search Speaker"
              />
            </div>
          </div>

          <div class="flex item-starts">
            <div class="flex h-5 items-center">
              <input
                id="candidates"
                name="candidates"
                type="checkbox"
                v-model="searchForm.advancedFiltering"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="candidates" class="font-medium text-gray-700"
                >Enable Advanced Filter</label
              >
            </div>
          </div>

          <div class="mt-4">
            <div class="grid grid-cols-5 gap-3">
              <div>
                <app-input
                  v-model:value="searchForm.speaker_name"
                  :style="`px-1 py-1 text-xs`"
                  placeholder="Speaker Name"
                />
              </div>
              <div>
                <app-select
                  :padding-class="`py-1 px-1 text-xs`"
                  placeholder="Gender"
                  :options="genders"
                  @optionPicked="optionPicked"
                  v-model:selectedOption="selectedGender"
                >
                </app-select>
              </div>
              <div>
                <app-input
                  v-model:value="searchForm.topics"
                  :style="`px-1 py-1 text-xs`"
                  placeholder="Topic Title"
                />
              </div>
              <div>
                <app-input
                  v-model:value="searchForm.fee_range"
                  :style="`px-1 py-1 text-xs`"
                  placeholder="Fee Range"
                />
              </div>
              <div>
                <app-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:value="searchForm.live_keynote_fee"
                  placeholder="Live Keynote Fee"
                />
              </div>

              <div>
                <app-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:value="searchForm.state"
                  placeholder="State"
                />
              </div>
              <div>
                <app-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:value="searchForm.city"
                  placeholder="City"
                />
              </div>
              <div>
                <app-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:value="searchForm.postcode"
                  placeholder="Post Code"
                />
              </div>

              <div>
                <app-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:value="searchForm.exclusivity"
                  placeholder="Exclusivity"
                />
              </div>
              <div>
                <multiple-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:modelValue="searchForm.expertises"
                  placeholder="Expertise"
                ></multiple-input>
              </div>
              <div>
                <multiple-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:modelValue="searchForm.categories"
                  placeholder="Categories"
                ></multiple-input>
              </div>
              <div>
                <multiple-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:modelValue="searchForm.industries"
                  placeholder="Industries"
                ></multiple-input>
              </div>
              <div>
                <multiple-input
                  :style="`px-1 py-1 text-xs`"
                  v-model:modelValue="searchForm.keywords"
                  placeholder="Keywords"
                ></multiple-input>
              </div>
            </div>
          </div>
          <div class="mt-4 flex justify-center">
            <button
              @click="filterSpeakers"
              class="inline-flex items-center px-4 py-1 text-sm font-semibold text-white bg-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150"
            >
              Search
            </button>
          </div>

          <div class="flex mt-4">
            <div class="overflow-x-auto relative h-80">
              <table
                class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
              >
                <thead
                  class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                >
                  <tr>
                    <th scope="col" class="px-6 py-3">No</th>
                    <th scope="col" class="px-6 py-3">Speaker Name</th>
                    <th scope="col" class="px-6 py-3">Conflict</th>
                    <th scope="col" class="px-6 py-3"></th>
                    <th scope="col" class="px-6 py-3">Exclusivity</th>
                    <th scope="col" class="px-6 py-3">Status</th>
                    <th scope="col" class="px-6 py-3">Gross Fee Range</th>
                    <th scope="col" class="px-6 py-3">Live Keynote Fee</th>
                    <th scope="col" class="px-6 py-3">Account Name</th>
                    <th scope="col" class="px-6 py-3">Contact Name</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="speakerList.length > 0">
                    <tr
                      v-for="(speaker, index) in speakerList"
                      :key="index"
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td class="px-6 py-4">
                        {{ index + 1 }}
                      </td>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        {{ speaker.speaker_name }}
                      </th>
                      <td class="px-6 py-4">
                        {{ speaker.availability == 1 ? "Yes" : "No" }}
                      </td>
                      <td class="px-6 py-4 text-right">
                        <a
                          href="#"
                          @click="occupy(speaker)"
                          v-if="speaker.availability != 1"
                          class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >Assign</a
                        >
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.exclusivity }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.status }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.fee_range }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.live_keynote_fee }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.account_name }}
                      </td>
                      <td class="px-6 py-4">
                        {{ speaker.contact_name }}
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="9" class="text-center text-gray-700">
                      No Data Available!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-center px-2 py-2 gap-4">
            <!--                <button @click.prevent="createTemplate" class="px-4 py-1 text-white bg-green-400 rounded-md">Submit-->
            <!--                </button>-->

            <button
              class="px-4 py-1 text-gray-700 bg-white ring-1 ring-inset ring-gray-700 rounded-md"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="showLoadingScreen"
        class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen"
      >
        <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
          {{ loadingText }}
        </loading>
      </div>
    </div>
  </div>
</template>

<script>
import AppLabel from "@/components/AppLabel";
import AppInput from "@/components/AppInput";
import MultipleInput from "@/components/MultipleInput";
import AppSelect from "@/components/AppSelect";
import {
  filterSpeakers,
  occupySpeakers,
} from "@/services/ShortlistedSpeakerService";
import Loading from "@/components/Loading";

const searchForm = {
  speaker_name: null,
  fee_range: null,
  exclusivity: null,
  live_keynote_fee: null,
  gender: null,
  state: null,
  city: null,
  postcode: null,
  categories: null,
  expertises: null,
  keywords: null,
  industries: null,
  topics: null,
  advancedFiltering: false,
  search: null,
  function_date: null,
};
export default {
  name: "pages.shortlisted-speakers.FilterShortlistedSpeakers",
  components: {
    AppLabel,
    AppInput,
    MultipleInput,
    AppSelect,
    Loading,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    dealInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedGender: null,
    genders: [
      { label: "Male", id: "Male" },
      { label: "Female", id: "Female" },
    ],
    searchForm: {
      ...searchForm,
    },
    advancedFiltering: false,
    search: null,
    speakerList: [],
    showLoadingScreen: false,
    loadingText: "Please Wait!",
  }),
  mounted() {},
  methods: {
    filterSpeakers() {
      this.loadingText = "Filtering Speakers";
      this.showLoadingScreen = true;
      this.searchForm.function_date = this.dealInfo.function_date;
      filterSpeakers(this.searchForm)
        .then((res) => {
          this.showLoadingScreen = false;
          this.speakerList = res.data;
        })
        .catch((err) => console.log(err));
    },
    optionPicked(option) {
      this.searchForm.gender = option.id;
    },
    occupy(speaker) {
      this.loadingText = "Assigning Speaker...";
      this.showLoadingScreen = true;
      let data = {
        deal: this.dealInfo,
        speaker: speaker,
      };
      occupySpeakers(data)
        .then((res) => {
          this.showLoadingScreen = false;
          speaker.availability = 1;
          this.$parent.fetchShortlistedSpeaker(this.dealInfo.deal_id);
        })
        .catch((err) => console.log(err));
    },
    closeModal() {
      this.searchForm = { ...searchForm };
      this.speakerList = [];
      this.$emit("update:isVisible", false);
    },
  },
};
</script>

<style scoped></style>
