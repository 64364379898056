<template>
    <div
        class="fixed z-50 top-0 left-0 flex items-center justify-center w-screen h-screen transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >
        <div class="bg-white rounded-lg ring-2 p-2">
            <div class="px-8 py-4">
                <h3><slot>Are you sure you want to perform this operation?</slot></h3>
            </div>

            <div class="flex justify-center gap-10">
                <button :class="`w-32 ${yesButtonBgClass} text-white px-6 py-2 rounded-md`" @click.prevent="yesClicked">
                    {{ yesMessage }}
                </button>
                <button :class="`w-32 ${noButtonBgClass} text-white px-6 py-2 rounded-md`" @click.prevent="noClicked">
                    {{ noMessage }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'components.ynDialogue',
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        yesMessage: {
            type: String,
            default: 'Yes',
        },
        noMessage: {
            type: String,
            default: 'Cancel',
        },
        yesButtonBgClass: {
            type: String,
            default: 'bg-red-400',
        },
        noButtonBgClass: {
            type: String,
            default: 'bg-green-400',
        },
    },
    methods: {
        yesClicked() {
            this.$emit('yesClicked')
        },
        noClicked() {
            this.$emit('noClicked')
        },
    },
}
</script>
