<template>
    <!-- <title>Generate Proposal</title> -->
    <div class="px-4 py-4">
        <div class="grid grid-cols-3 gap-2 ">
        
            <div class="col-span-1"><button class="bg-transparent hover:bg-rose-500 text-rose-700 font-semibold hover:text-white py-2 px-4 border border-rose-500 hover:border-transparent rounded" @click="sendFullProposal">Send Full Proposal</button></div>
            <div class="col-span-1"><button class="bg-transparent hover:bg-rose-500 text-rose-700 font-semibold hover:text-white py-2 px-4 border border-rose-500 hover:border-transparent rounded" @click="sendBriefProposal">Send Brief Proposal</button></div>
            <div class="col-span-1"><button class="bg-transparent hover:bg-rose-500 text-rose-700 font-semibold hover:text-white py-2 px-4 border border-rose-500 hover:border-transparent rounded" @click="sendProposalQuote">Send Proposal Quote</button></div>
          
        </div>
        <div class="py-4">
            <iframe
                :src="myIframe"
                width="100%"
                height="400"
                id="proposalFrame"
                frameborder="0" v-show="myIframe">
            </iframe>
                <div class="px-4">
                    <hr>
                </div>
                
        </div>
       
        <div class="py-4 px-8" v-if="myIframe">
            
            <form @submit="onSubmit">
                <div class="flex flex-col gap-6">
                    <div><app-label ><strong>To</strong> </app-label> <multiselect v-model="selected" :options="options" :multiple="true" :taggable="true" @tag="addTag" placeholder="Enter Receipents" required></multiselect></div>
                    <div> <app-label ><strong>Subject</strong> </app-label><app-input v-model:value="emailSubject" placeholder="Enter Subject" required></app-input></div>
                    <div> <app-label ><strong>Email Body</strong> </app-label> <app-textarea v-model:value="emailBody" placeholder="Enter Email Body" paddingClass="py-4 px-4 pb-12" required></app-textarea></div>
                    <div v-show="foundError"> <div class="flex items-center text-rose-500 mt-2 mb-4 ml-2" style="white-space: pre;">{{ errorMessage }}</div></div>
                    <div> <button type="submit" class="bg-transparent hover:bg-rose-500 text-rose-700 font-semibold hover:text-white py-2 px-4 border border-rose-500 hover:border-transparent rounded float-left">Send Email</button></div>
                </div>   
            </form>
        </div>
        <div v-if="showLoadingScreen" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-slate-300">
            <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
                {{ loadingText }}
            </loading>
        </div>
        <div
            class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
            :class="{'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,}"
        >
            <div class="px-10 py-5 bg-white rounded-lg ring-2 w-auto">
                <div class="flex items-center text-rose-500 mt-2 mb-4 ml-2" style="white-space: pre;">{{ message }}</div>
                <div class="flex justify-center mt-2">
                    <button
                        @click="showPopUpModal = false"
                        class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from "@/components/Loading";
import {generateReqProposal , sendEmailToClient  } from "@/services/ShortlistedSpeakerService";
import AppTextarea from "@/components/AppTextarea";
import AppInput from "@/components/AppInput";
import AppLabel from "@/components/AppLabel";
import Multiselect from 'vue-multiselect';

 
export default {
  name: 'MyIframe',
  components: {
    Loading,
    AppTextarea,
    AppInput,
    Multiselect,
    AppLabel
  },
  data: () => ({
    myIframe: null,
    showLoadingScreen: false,
    loadingText: 'Please Wait!',
    showPopUpModal: false,
    selected: [],
    options: [],
    message: '',
    contactEmail: '',
    emailSubject: '',
    emailBody: '',
    updatedEmailBody: '',
    client_email: '',
    cc_emails: '',
    foundError: false,
    errorMessage : '',
    proposal_name: null,
    dealInfo: {
        deal_id: null,
        name: null,
        client_name: null,
        contactId: null
    },
  }),
  head: {
    // creates a title tag in header.
    title () {
      return {
        inner: 'New Proposal'
      }
    },
    meta: [
      // creates a meta description tag in header.
      { name: 'New Proposal', content: 'New Proposal' }
    ]
  },
  async mounted() {
    await this.init()
  },
  /* global ZOHO */
  methods: {
    async init() {
        this.showLoadingScreen = true
        ZOHO.embeddedApp.on('PageLoad', async (data) => {
    
            await ZOHO.CRM.API.getRecord({
                Entity: data.Entity,
                RecordID: data.EntityId,
            }).then((response) => {
                let data = response.data[0];
                this.updatedDealFields(data);
                if (this.dealInfo.client_name === undefined)
                {
                    this.showPopUpNotification('Contact Not Assigned');
                    return false;
                }else {
                    this.getClientEmail(this.dealInfo.contactId);
                }
                this.showLoadingScreen = false;
            })
        })
        ZOHO.embeddedApp.init()
        
    },
    sendFullProposal(){
        console.log(this.dealInfo.deal_id);
        this.myIframe = null;
        this.showLoadingScreen = true;
        this.proposal_name = 'sendProposal';
        this.emailSubject = 'Send Full Proposal';
        let arg = {"opportunity_id": this.dealInfo.deal_id, "proposal_name": this.proposal_name}
        this.generateProposal(arg);    
    },
    sendBriefProposal(){
       
        this.myIframe = null;
        this.showLoadingScreen = true;
        this.proposal_name = 'sendBriefProposal';
        this.emailSubject = 'Saxton Brief Proposal';
        let arg = {"opportunity_id": this.dealInfo.deal_id, "proposal_name": this.proposal_name}
        this.generateProposal(arg);    
        
    },
    sendProposalQuote(){
        this.myIframe = null;
        this.showLoadingScreen = true;
        this.proposal_name = 'sendProposalQuote';
        this.emailSubject = 'Send Quote Proposal';
        let arg = {"opportunity_id": this.dealInfo.deal_id, "proposal_name": this.proposal_name}
        this.generateProposal(arg);   
    },
    async getClientEmail(contactId)
    {
       const contactRes =  await ZOHO.CRM.API.getRecord({
                Entity: 'Contacts',
                RecordID: contactId});
        if (contactRes.data)
        {
            let data = contactRes.data[0];
            this.contactEmail = data.Email; 
        }
    },
    async generateProposal(arg)
    {
        const proposalResponse = await generateReqProposal(arg);
        const res = proposalResponse.data;
        if (res.status == "success")
        {  
            this.showLoadingScreen = false;
            this.defaultEmailSetup(res.url);
            this.myIframe = res.url;
            this.changeSrc(this.myIframe);  
        } else 
        {
            this.showLoadingScreen = false;
            this.showPopUpNotification(res.message);
        }
    },
    showPopUpNotification(msg){
        this.message = msg;
        this.showPopUpModal = true;
    },
    updatedDealFields(data)
    {
        this.dealInfo.deal_id     = data.id;
        this.dealInfo.name        = data.Deal_Name;
        this.dealInfo.client_name = data.Contact_Name?.name;
        this.dealInfo.contactId   = data.Contact_Name?.id;
    },
    defaultEmailSetup(public_url)
    {
        if (this.contactEmail)
        {
            this.options = [];
            this.selected = [];
            this.options.push(this.contactEmail);
            this.selected.push(this.contactEmail);
        }
        this.emailBody = this.generateEmailBody(public_url,this.dealInfo.client_name,'Saxton');
    },
    changeSrc(fullPath)
    {
        top.document.getElementById('proposalFrame').setAttribute("src",fullPath);
    },
    addTag (newTag) {
       
        this.options.push(newTag)
        this.selected.push(newTag)
    }, 
    async onSubmit(event) {
        event.preventDefault();
        if (this.selected.length == 0)
        {
            this.foundError = true;
          this.errorMessage = 'No recepient is selected';
        }
        else if (this.emailSubject == '')
        {
            this.foundError = true;
            this.errorMessage = 'Email subject is empty.';
        } else if (this.emailBody == '')
        {
            this.foundError = true;
            this.errorMessage = 'Email Body is empty.'; 
        } else {
            this.selected.forEach((element, index) => {
                if (index == 0)
                {
                    this.client_email = element;
                } else {
                    let new_cc_email = (this.cc_emails != '') ? ','+element : element;
                    this.cc_emails +=  new_cc_email;
                }

            });
            this.showLoadingScreen = true;
            this.loadingText = 'Sending...';
            this.updatedEmailBody = this.emailBody.replaceAll('\n', '<br />');
            const email_param =  {
                "email_subject": this.emailSubject,
                "public_url": this.myIframe,
                "client_email": this.client_email,
                "cc_emails": this.cc_emails,
                "email_body": this.updatedEmailBody,
                "dealId": this.dealInfo.deal_id, 
                "proposal_name": this.emailSubject
            };
            const proposalResponse = await sendEmailToClient(email_param);
            const res = proposalResponse.data;
            if (res.status == "success")
            { 
                this.showLoadingScreen = false;
                this.showPopUpNotification('Email is sent successfully!');
            } else{
                this.showLoadingScreen = false;
                this.showPopUpNotification('Email is not sent!');
            }
        }
       
    }
    ,generateEmailBody(url,client_name,user_name)
    {
        let emailBody = 'Dear '+client_name+',\n\nThank you for your enquiry for your upcoming event, I would be delighted to assist you. \nI’ve put together a selection of suggested talent as per your brief via our online proposal link for your perusal here: '+url+' \nIf you would like me to check availability of any of the proposed talent for a selected date, please let me know.\nPlease don\'t hesitate to contact me if you require any further information or if you would like more ideas to discuss.\nI look forward to your working with you.\n\nKind regards,\n'+user_name;
        return emailBody;
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>