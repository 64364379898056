<template>

    <div
        class="fixed z-50 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full	 bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >
        <div class="px-5 py-5 bg-white rounded-lg  w-128">
            <div
                class=" flex flex-shrink-0 items-center justify-between rounded-t-md">
                <span class="flex justify-between">
                    <svg @click="handleEventClick" class="w-6 h-6 cursor-pointer stroke-gray-400 hover:stroke-blue-500"
                         fill="none" data-bs-toggle="tooltip" title="Hi! I'm tooltip"
                         stroke="#374151" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                    <svg @click="showDeleteConfirmation=true"
                         class="w-6 h-6 ml-2 cursor-pointer stroke-gray-400 hover:stroke-red-500 " fill="none"
                         stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                </span>

                <span>
                    <svg @click="cancelled" class="w-6 h-6 cursor-pointer stroke-gray-400 hover:stroke-red-500"
                         fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path></svg>
                </span>
            </div>
            <div class="mt-3  grid grid-rows-3 gap-2 w-full">
                <div class="flex justify-start">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-6 h-6 stroke-gray-500 ">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"/>
                        </svg>
                    </div>
                    <div class="text-justify">
                        <span class="pl-2">
                            <b> {{ eventDetail?.title }} </b>
                        </span>
                    </div>
                </div>

                <div class="flex">
                    <span class="text-gray-500">{{ eventDate }}</span>
                </div>
                <div class="flex justify-start">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 stroke-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
                        </svg>
                    </div>
                    <div>
                        <span class="pl-2 ">{{ eventDetail?.speaker_name }}</span>
                    </div>
                </div>

                <div class="flex justify-start">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 stroke-gray-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"/>
                        </svg>
                    </div>
                    <div class="text-justify">
                        <span class="pl-2 "> {{ eventDetail?.notes }} </span>
                    </div>
                </div>

                <!--                <div class="flex justify-start">-->
                <!--                    <div>-->
                <!--                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"-->
                <!--                             stroke="currentColor" class="w-5 h-5 stroke-gray-500">-->
                <!--                            <path stroke-linecap="round" stroke-linejoin="round"-->
                <!--                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>-->
                <!--                            <path stroke-linecap="round" stroke-linejoin="round"-->
                <!--                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>-->
                <!--                        </svg>-->
                <!--                    </div>-->
                <!--                    <div>-->
                <!--                        <span class="pl-2 ">Lorem ipsum dolor sit amet.  </span>-->
                <!--                    </div>-->
                <!--                </div>-->

            </div>
            <y-n-dialogue :isVisible="showDeleteConfirmation" @yesClicked="deleteConfirmed" @noClicked="deleteCanceled">
                Do you want to Delete this event?
            </y-n-dialogue>
        </div>

    </div>

    <add-event
        v-model:is-visible="openAddEventModal"
        :event="event"
        @eventCreated="eventCreated"
    />

</template>

<script>
import AddEvent from "@/components/AddEvent";
import YNDialogue from "@/components/YNDialogue";
import axios from "axios";
import {deleteEvent} from "@/services/CalendarEventService";

export default {
    name: "EventDetails",
    components: {
        AddEvent,
        YNDialogue
    },
    data() {
        return {
            openAddEventModal: false,
            eventDetail: null,
            showDeleteConfirmation: false
        }
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: false
        }
    },
    watch: {
        'event': function (val) {
            this.eventDetail = {...val}
        }
    },
    methods: {
        cancelled() {
            this.$emit('update:isVisible', false)
        },
        handleEventClick() {
            this.$emit('update:isVisible', false)
            this.openAddEventModal = true


            // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            //     clickInfo.event.remove()
            // }
        },
        eventCreated() {
            this.$emit('eventInit')
        },
        deleteConfirmed() {
            // this.$store.dispatch('zoho/revokeToken')
            if (this.eventDetail.id) {
                deleteEvent(this.eventDetail.id)
                    .then((res) => {
                        this.showDeleteConfirmation = false
                        this.$emit('eventInit')
                        this.cancelled();
                    }).catch(err => console.log(err))

            }
            this.showDeleteConfirmation = false
        },

        deleteCanceled() {
            this.showDeleteConfirmation = false
        },
    },
    computed: {
        eventDate() {
            if (this.eventDetail) {
                // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minute:'numeric' }
                let options = {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }
                let start = new Date(this.eventDetail.start_date);
                let end = new Date(this.eventDetail.end_date);
                return start.toLocaleDateString("en-US", options) + ' - ' + end.toLocaleDateString("en-US", options);
            }
        }
    }
}
</script>

<style scoped>

</style>