<template>
    <div class="border-2 rounded-lg w-full min-h-full max-h-12 overflow-y-scroll scrollbar-hide">
                    <span class="border border-gray-300 rounded-md mr-2 my-1 px-2 py-0 inline-block"
                          v-for="(item, index) in items" :key="item">
                        <span class="mr-1 text-xs">{{ item }}</span>
                        <button class="text-red-300 hover:text-red-900" @click="deleteItem(index)">x</button>
                    </span>
        <input
            ref="itemInput"
            v-model="item"
            type="text"
            :placeholder="`${placeholder}`"
            @keyup.,="addItemWithSpecialChar"
            @keyup.ctrl.v="addItem"
            @keyup.enter="addItem"
            class="focus:outline-none text-xs"
        />
    </div>
</template>

<script>
export default {
    name: "MultipleInput",
    props: {
        // eslint-disable-next-line vue/require-prop-type-constructor
        modelValue: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
    },
    data: () => ({
        items: [],
        item: null
    }),
    methods: {
        addItemWithSpecialChar(){
            if (this.item.trim() && this.item.slice(0,-1).trim()){
                let item = this.item.slice(0,-1).trim()
                this.items.push(item)

                this.$refs.itemInput.focus()
                this.$emit('update:modelValue', this.items);
            }
            this.item = null

        },
        addItem() {
            if (this.item && this.item.trim()){
                let item = this.item.trim()
                this.items.push(item)
                this.$refs.itemInput.focus()
                this.$emit('update:modelValue', this.items);
            }
            this.item = null

        },
        deleteItem(idx) {
            this.items.splice(idx, 1)
            this.$emit('update:modelValue', this.items);
            this.$refs.itemInput.focus()
        },
    }
}
</script>

<style scoped>
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>