import axios from "axios";

var backendApi = process.env.VUE_APP_BACKEND_API_URL;

export async function fetchShortListedSpeaker(crmId) {
    return await axios.get(`${backendApi}/api/shortlisted-speakers/${crmId}/option`);
}

export async function fetchEvents(shortListedSpeakerId) {
    return await axios.get(`${backendApi}/api/calender-event/${shortListedSpeakerId}/events`);
}

export async function deleteEvent(eventId) {
    return await axios.delete(`${backendApi}/api/calender-event/${eventId}`)
}

export async function createEvent(data) {
    return await axios.post(`${backendApi}/api/calender-event/store`, data)
}

export async function updateEvent(eventId, data) {
    return await axios.put(`${backendApi}/api/calender-event/${eventId}`, data)
}

export async function fetchSpeakerEvents(speakerId) {
    return await axios.get(`${backendApi}/api/calender-event/speaker/${speakerId}/events`);
}
export async function fetchCrmSpeakerInfo(speakerCrmId) {
    return await axios.get(`${backendApi}/api/calender-event/speaker/${speakerCrmId}`);
}