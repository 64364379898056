<template>
    <div v-click-outside="onClickOutside" class="w-full">
        <div class="border-2 flex w-full relative bg-white items-center rounded-lg">
            <div class="relative w-full">

                <input @click="dropdownButtonClicked" :readonly="true"
                       :class="` ${paddingClass} w-full  focus:outline-none  ${selectedOption ? 'text-gray-700' : 'text-gray-400'} bg-white rounded`"
                       :value="selectedOption ? selectedOption.label : placeholder"

                />
                <div class="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </svg>
                </div>

                <ul class="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    :class="dropDownVisible ? 'block' : 'opacity-0 invisible pointer-events-none'">
                    <li v-for="option in options" :key="option.label" @click.prevent="optionClicked(option)" class="m-0.5">
                        <a class="text-gray-700  block px-2 py-1 text-sm hover:bg-blue-300" :class="option?.id == selectedOption?.id ? 'bg-blue-500 unselectable' : ''"
                           href="#">{{ option.label }}</a>
                    </li>
                    <li v-if="options.length <= 0" @click.prevent="hideOptions()">
                        <a class="block px-4 py-2 whitespace-no-wrap bg-gray-300 hover:bg-gray-400 hover:text-white"
                           href="#">Select one</a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
    name: 'components.appSelect',
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        options: {
            required: true,
            type: Array,
        },
        selectedOption: {
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        paddingClass: {
            type: String,
            required: false,
            default: 'py-2 px-3',
        },
    },
    data: () => ({
        dropDownVisible: false,
        readonly: true
    }),
    methods: {
        dropdownButtonClicked() {
            this.readonly = false
            this.dropDownVisible = !this.dropDownVisible
        },
        optionClicked(option) {
            this.dropDownVisible = false
            this.readonly = true
            this.$emit('update:selectedOption', option)
            this.$emit('optionPicked', option)
        },
        onClickOutside() {
            this.readonly = true
            if (this.dropDownVisible) {
                this.dropDownVisible = !this.dropDownVisible
            }
        },
        hideOptions() {
            this.dropDownVisible = false
            this.readonly = true
        }
    },
}
</script>
<style scoped>
.unselectable{
    -webkit-user-select: none;
}
</style>
