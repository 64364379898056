export const getISOStringWithoutSecsAndMilliSecs = (date) => {
    const dateAndTime = date.toISOString().split('T')
    const time = dateAndTime[1].split(':')

    return dateAndTime[0] + 'T' + time[0] + ':' + time[1]
}

export const getLocalISOTime = (date) => {
    let dateTime = new Date(date)
    let tzoffset = dateTime.getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(dateTime - tzoffset)).toISOString().slice(0, -1);
}

export default {
    getISOStringWithoutSecsAndMilliSecs,
    getLocalISOTime
}
