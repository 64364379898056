<template>
    <!-- component -->
    <!-- This is an example component -->

    <div class="mx-4 mt-4">
        
        <div class="flex justify-center">
            <form enctype="multipart/form-data"> 
                <div class="grid gap-4 grid-cols-2">
                
                    <div class="mt-4">
                        <div class="flex mt-4">
                            <app-input-file ref="documents" @change="uploadImage"></app-input-file>
                        </div>

                    </div>
                    <div class="mt-4 py-4">
                        
                        <button
                            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                            @click.prevent="processGenerateInvoice">
                            Upload Image
                        </button>             
                    </div>
                    <div>
                        <div class="flex flex-col ..." v-show="(! photoPreview && current_photo)">

                            <!-- Current Image -->
                            <div class="flex justify-center" >
                                <img :src="current_photo" :alt="image" class="block w-80 h-80 bg-cover bg-no-repeat bg-center">
                                
                            </div>
                            <div class="py-2"><span> {{current_photo_name}}</span></div>

                        </div>
                        <!-- New Image -->
                        <div class="flex justify-center" v-show="photoPreview">

                            <div class="mt-2" v-show="photoPreview">
                                <span class="block w-80 h-80 bg-cover bg-no-repeat bg-center"
                                        :style="'background-image: url(\'' + photoPreview + '\');'">
                                </span>
                                
                            </div>

                        </div>

                    </div>
                    <div>
                        <div class="mt-4 px-8" v-show="(! photoPreview && current_photo)">
                            <a
                                class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                                :href="current_photo" target="_blank"
                            >
                                Download Image
                        </a>
                        </div>

                    </div>
                </div>
            </form>
        </div>

        

      
        
        <div v-if="showLoadingScreen"
             class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-slate-300">
            <loading>Please Wait...</loading>
        </div>
        <div v-if="loading" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen">
            <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
                {{ loadingText }}
            </loading>
        </div>
    </div>

    <div
        class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
                'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,
            }"
    >
        <div class="px-10 py-5 bg-white rounded-lg ring-2 w-96">
            <div class="flex items-center text-blue-500 mt-2 mb-4 ml-2">{{ message }}</div>
            <div class="flex justify-center mt-2">
                <button
                    @click="showPopUpModal = false"
                    class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
                >
                    Ok
                </button>
            </div>
        </div>
    </div>


</template>

<script>
//import AppLabel from "@/components/AppLabel";
import Loading from "@/components/Loading";
import AppInputFile from "@/components/AppInputFile";
import {fetchUplaodedImage, uploadSpeakerImage} from "@/services/UploadImageService";
//import axios from "axios";

export default {
    name: "pages.shortlisted-speakers.GenerateInvoice",
    components: {
        //AppLabel,
        Loading,
        AppInputFile

    },
    data: () => ({
        
        loadingText: 'Please Wait...',
        showLoadingScreen: false,
        loading: false,
        showPopUpModal: false,
        message: null,
        imageObj: null,
        error_msg: "",
        current_photo: null,
        current_photo_name: null,
        photoPreview: null,
        speaker_crm_id: null

    }),
    async mounted() {
        await this.init()
    },

    /* global ZOHO */
    methods: {
        async init() {
            this.showLoadingScreen = true;
            ZOHO.embeddedApp.on('PageLoad', async (data) => {
                this.speaker_crm_id = data.EntityId;
                try{
                    const res = await ZOHO.CRM.API.getRecord({Entity:"Speakers",RecordID:data.EntityId});
                    if(res.data[0].Profile_Image_URL)
                    {
                        console.log(res.data[0].Profile_Image_URL)
                        this.current_photo = res.data[0].Profile_Image_URL;
                    }
            
                    const speaker_res = await fetchUplaodedImage(this.speaker_crm_id);
                    let image_name = speaker_res.data.speaker_image_name;
                    this.current_photo_name = image_name;
                    this.showLoadingScreen = false;
                    
                }catch(error) {
                    this.showLoadingScreen = false;
                    console.error(error);
                }
            })
            ZOHO.embeddedApp.init()
        },
        uploadImage(event){
            
            if (event.target.files) {
                this.photoPreview = null;
                const files = event.target.files[0];
                
                
                if (! files) return;

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.photoPreview = e.target.result;
                };

                reader.readAsDataURL(files);

                this.imageObj = files;
            }
        },
        setPreviewImage(image_path)
        {
            const reader = new FileReader();

                reader.onload = (e) => {
                    this.photoPreview = e.target.result;
                };

            reader.readAsDataURL(image_path);

        },
        async processGenerateInvoice()
        {
            if (this.imageObj)
            {
                const file_type = this.imageObj.type;
                const file_size = this.imageObj.size/1024;

                if(file_type != 'image/jpeg' && file_type != 'image/png' && file_type !='image/jpg')
                {
                    this.error_msg = file_type+' is not supported.Only JPEG/JPG/PNG supported.';
                }
                else if(file_size > 5120)
                {
                    this.error_msg = 'File size is more than 5MB.';
                }
                else{
                    this.error_msg = '';
                }

                if(this.error_msg)
                {
                    this.message = this.error_msg;
                    this.showPopUpModal = true;
                   
                }else{
                    this.showLoadingScreen = true;
                    this.loading= true;
                    this.showPopUpModal = false;

                    let formData = new FormData();
                    formData.append('file', this.imageObj);
                    formData.append('file_name', this.imageObj.name);
                    formData.append('crm_id', this.speaker_crm_id);
                    
                    try {
                        const config = {
                            headers: { 'content-type': 'multipart/form-data' }
                        }
                        let res = await uploadSpeakerImage(formData,config);
                        if(res.data.status == 'Success' || res.data.status == 'Error' ) {
                            this.showLoadingScreen = false;
                            this.loading= false;
                            this.message = res.data.message;
                            this.showPopUpModal = true; 
                        }
                    } catch(error) {
                        console.error(error);
                    }                
                }                           
            } else {
                this.message = 'No file selected';
                this.showPopUpModal = true; 
            }
        },
        /*async downloadImage(image_url)
        {
            await axios({
                url: image_url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.current_photo_name);
                document.body.appendChild(fileLink);

                fileLink.click();

            })
          
        }*/
    }

}
</script>