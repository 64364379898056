<template>
    <div class="relative mx-6 mt-4">
        <!--        <div class="flex justify-between">-->
        <!--            <div class="w-1/6 h-1/4">-->
        <!--                <AppSelect @optionPicked="changedSpeaker" :options="shortListedSpeakers"-->
        <!--                           v-model:selectedOption="selectedSpeaker"/>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class='mt-4' v-if="showFullCalendar">
            <FullCalendar
                ref="fullCalendar"
                class='demo-app-calendar'
                :options='calendarOptions'
                :events="currentEvents"
            >
                <template v-slot:eventContent='arg'>
                    <div class="flex items-baseline">
                        <!--                        <span class="inline-block w-2 h-2 mr-2 bg-red-600 rounded-full"></span>-->
                        <span
                            class="inline-flex  items-center justify-center px-2 py-1 text-xs font-bold
                             leading-none text-indigo-100  rounded"
                            :class="

                            badgeColor[arg.event.extendedProps.status]  ? badgeColor[arg.event.extendedProps.status] : 'bg-indigo-700'"
                        >
                            {{
                                arg.timeText + ' '
                            }}
                        </span>
                        <div
                            class="ml-2 max-w-2 fc-event-title fc-sticky block text-white-600 text-md font-semibold tracking-wide"
                            :style='arg.view.type == "dayGridMonth" ?`max-width: 120px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap` : null '>
                            {{ arg.event.title + '  ' }}
                        </div>
                    </div>
                    <!--                    <b>{{ arg.timeText + ' ' }}</b>-->
                    <!--                                        <i>{{ arg.event.title }}</i>-->
                </template>
            </FullCalendar>

        </div>
    </div>
    <!--    <test/>-->
    <add-event
        v-model:is-visible="openAddEventModal"
        @eventCreated="eventCreated"
        :event="eventForm"
    />
    <EventDetails
        v-model:is-visible="openEventDetailsModal"
        :event="eventDetails"
        @eventInit="eventCreated"
    />

    <div v-if="showLoadingScreen" class="flex justify-center mt-4">
        <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">Please wait...</loading>
    </div>

</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import AddEvent from "@/components/AddEvent";
import EventDetails from "@/components/EventDetails";
import AppSelect from "@/components/AppSelect";
import {getLocalISOTime} from "@/utils"
import {fetchEvents, fetchSpeakerEvents, fetchCrmSpeakerInfo} from "@/services/CalendarEventService";
import Loading from "@/components/Loading";

export default {
    name: "views-calendar.SpeakerCalenders",
    components: {
        FullCalendar,
        AddEvent,
        EventDetails,
        AppSelect,
        Loading

    },
    data() {
        return {
            calendarOptions: {
                height: 600,
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prevYear,prev,next,nextYear today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                },

                initialView: 'dayGridMonth',
                events: [],
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: 2,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                // eventRender: this.handleEventClick,
                eventDataTransform: function (event) {
                    event.start = event.start_date;
                    event.end = event.end_date;
                    return event;
                },
                /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
            },
            currentEvents: [],
            openAddEventModal: false,
            openEventDetailsModal: false,
            eventDetails: null,
            eventList: [],
            shortListedSpeakers: [],
            selectedSpeaker: null,
            shortlisted_speaker_id: null,
            eventForm: null,
            badgeColor: {
                Held: 'bg-orange-700',
                Booked: 'bg-green-700',
                Released: 'bg-red-700',
                Postponed: 'bg-yellow-700'
            },
            speakerInfo: null,
            loadingText: 'Please Wait...',
            showLoadingScreen: false,
            showFullCalendar: false

        }
    },
    computed: {},
    methods: {
        handleDateSelect(selectInfo) {
            if (this.speakerInfo.id) {
                this.eventForm = {
                    'start_date': selectInfo.view.type === 'dayGridMonth' ? getLocalISOTime(selectInfo.start) : new Date(selectInfo.start).toISOString(),
                    'end_date': selectInfo.view.type === 'dayGridMonth' ? getLocalISOTime(selectInfo.end) : new Date(selectInfo.end).toISOString(),
                    'speaker_id': this.speakerInfo.id,
                    'speaker_name': this.speakerInfo.speaker_name
                }
                this.openAddEventModal = true
            }
        },
        handleEventClick(clickInfo) {
            this.eventDetails = {
                ...clickInfo.event.extendedProps,
                id: clickInfo.event._def.publicId,
                title: clickInfo.event._def.title
            }
            this.openEventDetailsModal = true
        },
        handleEvents(events) {
            this.currentEvents = events
        },

        async fetchEvents() {
            if (this.selectedSpeaker && this.selectedSpeaker.id) {
                let shortListedSpeakerId = this.selectedSpeaker.id;
                await fetchEvents(shortListedSpeakerId)
                    .then((res) => {
                        this.eventList = res.data
                    }).catch(err => console.log(err))
            }
        },
        eventCreated() {
            this.fetchSpeakerEvents(this.speakerInfo.id)
        },
        async initCalendar() {
            await this.fetchEvents()
            this.calendarOptions.events = this.eventList;
        },
        changedSpeaker(event) {
            this.shortlisted_speaker_id = this.selectedSpeaker.id
            this.initCalendar()
        },

        fetchSpeakerEvents(speakerId) {
            fetchSpeakerEvents(speakerId).then((res) => {
                this.calendarOptions.events = res.data
                this.showLoadingScreen = false
                this.showFullCalendar = true
            }).catch(err => console.log(err))

        },
        fetchSpeakerInfo(crmId) {
            fetchCrmSpeakerInfo(crmId).then(({data}) => {
                this.speakerInfo = data;
                this.fetchSpeakerEvents(this.speakerInfo.id)
            }).catch(err => console.log(err))
        },
        async init() {
            this.showLoadingScreen = true
            ZOHO.embeddedApp.on('PageLoad', async (data) => {
                // let entityId = data.EntityId
                await ZOHO.CRM.API.getRecord({
                    Entity: data.Entity,
                    RecordID: data.EntityId,
                }).then((response) => {
                    let data = response.data[0];
                    this.fetchSpeakerInfo(data.id);
                })
            })
            ZOHO.embeddedApp.init()
        },
    },


    created() {
        this.init();
    }

}
</script>

<style lang='css'>

h2 {
    margin: 0;
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0 0 0 1.5em;
}

li {
    margin: 1.5em 0;
    padding: 0;
}

b { /* used for event dates/times */
    margin-right: 3px;
}

.fc { /* the calendar root */
    margin: 0 auto;
}

.fc .fc-popover {
    z-index: 4;
}
</style>
