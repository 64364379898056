<template>
    <div>
        <input
            :class="`${bgColorClass} ${style} rounded-lg appearance-none border-2 ${borderColorClass} ${focusClass} rounded w-full ${paddingClass} ${fgColorClass} leading-tight`"
            :value="value"
            type="file"
            @input="$emit('update:value', $event.target.value)"
            :disabled="disabled"
        />
    </div>
</template>

<script>
export default {
    name: 'components.appInputFile',
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: '',
        },
        style: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectAtClick: {
            type: Boolean,
            required: false,
            default: false,
        },
        bgColorClass: {
            type: String,
            required: false,
            default: 'bg-white',
        },
        fgColorClass: {
            type: String,
            required: false,
            default: 'text-gray-700',
        },
        borderColorClass: {
            type: String,
            required: false,
            default: 'border-gray-200',
        },
        focusClass: {
            type: String,
            required: false,
            default: 'focus:outline-none focus:ring-2 focus:ring-gray-400',
        },
        paddingClass: {
            type: String,
            required: false,
            default: 'py-2 px-4',
        },
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>
