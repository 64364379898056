<template>
    <div
        class="fixed z-40 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full	 bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >

        <div class="px-5 py-5 bg-white rounded-lg  w-128">


            <div class="flex gap-2 justify-center mt-2">
                <button @click="generateFile"
                    class="w-1/2 px-4 text-sm font-semibold text-white bg-blue-500 border-2 border-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                    Generate
                </button>
                <button
                    @click="$emit('update:isVisible', false)"
                    class="w-1/2 px-4 text-sm font-semibold text-white bg-blue-500 border-2 border-blue-500 shadow leading-6 rounded-md hover:bg-blue-600 transition ease-in-out duration-150">
                    Cancel
                </button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "GenerateFile",
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        dealInfo: {
            type: Object,
            required: true,
        }
    },
    methods: {
        generateFile() {
            console.log(this.dealInfo);
        }
    }
}
</script>

<style scoped>

</style>