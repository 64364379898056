<template>
    <div class="relative mx-6 mt-4">
        <div class="flex justify-between">
            <div class="w-1/6 h-1/4">
                <AppSelect @optionPicked="changedSpeaker" :options="shortListedSpeakers"
                           v-model:selectedOption="selectedSpeaker"/>
            </div>
        </div>

        <div class='mt-4'>
            <FullCalendar
                ref="fullCalendar"
                class='demo-app-calendar'
                :options='calendarOptions'
                :events="currentEvents"
            >
                <template v-slot:eventContent='arg'>
                    <div class="flex items-baseline">
                        <span
                            class="inline-flex  items-center justify-center px-2 py-1 text-xs font-bold
                             leading-none text-indigo-100  rounded"
                            :class="
                            badgeColor[arg.event.extendedProps.status]  ? badgeColor[arg.event.extendedProps.status] : 'bg-indigo-700'"
                        >
                            {{
                                arg.timeText + ' '
                            }}
                        </span>
                        <div
                            class="ml-2 max-w-2 fc-event-title fc-sticky block text-white-600 text-md font-semibold tracking-wide"
                            :style='arg.view.type == "dayGridMonth" ?`max-width: 120px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap` : null '>
                            {{ arg.event.title + '  ' }}
                        </div>
                    </div>
                </template>
            </FullCalendar>
        </div>
    </div>
    <!--    <test/>-->
    <add-event
        v-model:is-visible="openAddEventModal"
        @eventCreated="eventCreated"
        :event="eventForm"
    />
    <EventDetails
        v-model:is-visible="openEventDetailsModal"
        :event="eventDetails"
        @eventInit="eventCreated"
    />
    <div v-if="showLoadingScreen" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen">
        <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
            Please wait...
        </loading>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import AddEvent from "@/components/AddEvent";
import EventDetails from "@/components/EventDetails";
import AppSelect from "@/components/AppSelect";
import {getLocalISOTime} from "@/utils"
import {fetchEvents, fetchShortListedSpeaker, fetchSpeakerEvents} from "@/services/CalendarEventService";
import Loading from "@/components/Loading";

export default {
    name: "views-calendar.Calenders",
    components: {
        FullCalendar,
        AddEvent,
        EventDetails,
        AppSelect,
        Loading
    },
    data() {
        return {
            calendarOptions: {
                height: 600,
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prevYear,prev,next,nextYear today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                },

                initialView: 'dayGridMonth',
                events: [],
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: 2,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                // eventRender: this.handleEventClick,
                eventDataTransform: function (event) {
                    event.start = event.start_date;
                    event.end = event.end_date;
                    return event;
                },
                /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
            },
            currentEvents: [],
            openAddEventModal: false,
            openEventDetailsModal: false,
            eventDetails: null,
            eventList: [],
            shortListedSpeakers: [],
            selectedSpeaker: null,
            shortlisted_speaker_id: null,
            speakerId: null,
            speakerName: null,
            eventForm: null,
            badgeColor: {
                Held: 'bg-orange-700',
                Booked: 'bg-green-700',
                Released: 'bg-red-700',
                Postponed: 'bg-yellow-700'
            },
            showLoadingScreen: false,
            showFullCalendar: false


        }
    },
    computed: {},
    methods: {
        handleDateSelect(selectInfo) {
            if (this.shortlisted_speaker_id) {
                this.eventForm = {
                    'start_date': selectInfo.view.type === 'dayGridMonth' ? getLocalISOTime(selectInfo.start) : new Date(selectInfo.start).toISOString(),
                    'end_date': selectInfo.view.type === 'dayGridMonth' ? getLocalISOTime(selectInfo.end) : new Date(selectInfo.end).toISOString(),
                    'shortlisted_speaker_id': this.shortlisted_speaker_id,
                    'speaker_id': this.speakerId,
                    'speaker_name': this.speakerName
                }
                this.openAddEventModal = true
            }
        },
        handleEventClick(clickInfo) {
            this.eventDetails = {
                ...clickInfo.event.extendedProps,
                id: clickInfo.event._def.publicId,
                title: clickInfo.event._def.title
            }
            this.openEventDetailsModal = true
        },
        handleEvents(events) {
            this.currentEvents = events
        },

        async fetchEvents() {
            if (this.selectedSpeaker && this.selectedSpeaker.id) {
                let shortListedSpeakerId = this.selectedSpeaker.id;
                let speakerId = this.speakerId
                await fetchSpeakerEvents(speakerId)
                    .then((res) => {
                        this.eventList = res.data
                        this.showLoadingScreen = false
                    }).catch(err => console.log(err))
            }
        },
        eventCreated() {
            this.initCalendar()
        },
        async initCalendar() {
            await this.fetchEvents()
            this.calendarOptions.events = this.eventList;
        },
        fetchShortListedSpeaker(dealId) {
            fetchShortListedSpeaker(dealId)
                .then(({data}) => {
                    if (data.length > 0) {
                        this.shortListedSpeakers = data;
                        this.selectedSpeaker = this.selectedSpeaker ? this.selectedSpeaker : this.shortListedSpeakers[0];
                        this.shortlisted_speaker_id = this.selectedSpeaker.id
                        this.speakerId = this.selectedSpeaker.speaker_id
                        this.speakerName = this.selectedSpeaker.label
                        this.initCalendar()
                    }
                    this.showLoadingScreen = false

                }).catch(err => console.log(err))
        },
        changedSpeaker(event) {
            this.showLoadingScreen = true
            this.shortlisted_speaker_id = this.selectedSpeaker.id
            this.initCalendar()
        },
        async init() {
            this.showLoadingScreen = true
            ZOHO.embeddedApp.on('PageLoad', async (data) => {
                // let entityId = data.EntityId
                await ZOHO.CRM.API.getRecord({
                    Entity: data.Entity,
                    RecordID: data.EntityId,
                }).then((response) => {
                    let data = response.data[0];
                    this.fetchShortListedSpeaker(data.id);
                })
            })
            ZOHO.embeddedApp.init()
        },
    },


    created() {
        // this.fetchShortListedSpeaker();
        this.init();
    }

}
</script>

<style lang='css'>

h2 {
    margin: 0;
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0 0 0 1.5em;
}

li {
    margin: 1.5em 0;
    padding: 0;
}

b { /* used for event dates/times */
    margin-right: 3px;
}

.fc { /* the calendar root */
    margin: 0 auto;
}

.fc .fc-popover {
    z-index: 4;
}
</style>
