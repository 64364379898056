<template>
    <div>
        <label :class="`block ${fgColorClass} ${bgColorClass} text-left mb-0`" :for="labelFor">
            <slot></slot>
        </label>
    </div>
</template>

<script>
export default {
    name: 'components.appLabel',
    props: {
        bgColorClass: {
            type: String,
            required: false,
            default: 'bg-white',
        },
        fgColorClass: {
            type: String,
            required: false,
            default: 'text-gray-700',
        },
        labelFor:{
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>
