<template>
    <div
        class="fixed z-4 top-0 left-0 flex items-center justify-center outline-none overflow-x-hidden overflow-y-auto w-full h-full	 bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
            'opacity-0 invisible pointer-events-none scale-75': !isVisible,
        }"
    >
        <div class="p-2 border-1  rounded-lg w-3/4 h-1/4 ">

            <div class="h-3/5	">
                <div class="p-4 bg-white rounded-lg w-full ">
                    <div class="flex gap-2 justify-center mt-2">
                        <div>
                            <h3 class="font-bold">Generate PO</h3>
                            <div class="font-normal text-sm">Client Name: <span class="font-semibold">{{ dealInfo.client_name }}</span></div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="grid grid-cols-1 justify-items-center">
                            <div class="items-start">
                               
                                <div class="grid grid-rows-1 items-center gap-3 mt-2">
                                   
                                    <div class="flex item-center">
                                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead class="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" class="px-6 py-3">
                                                       Speaker Name
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Commission
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Gross Amount
                                                    </th>
                                                    <th scope="col" class="px-6 py-3">
                                                        Net amount
                                                    </th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody class=" text-center">
                                                <tr v-for="(item) in shortListedSpeakersInfo" :key="item.id">
                                                    <td> {{ item.name }}</td>
                                                    <td>
                                                        <app-input :style="`px-1 py-2 text-xs`" v-model:value="item.commission" disabled/>
                                                    </td>
                                                    <td>{{ item.listprice }}</td>
                                                    <td>{{ sumNetAmount(item.listprice,item.commission) }}</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 flex justify-center" id="footer">
                        <button @click="generatePO"
                                :disabled='isDisabled' :class="['inline-flex items-center px-4 py-1 text-sm font-semibold text-white  shadow leading-6 rounded-md transition ease-in-out duration-150', isDisabled ? ' bg-gray-400 hover:bg-gray-400' : ' bg-blue-500 hover:bg-blue-600 cursor-pointer']"
                        > Generate
                        </button>
                        <button class="px-4 py-1 ml-2 text-gray-700 bg-white ring-1 ring-inset ring-gray-700 rounded-md" @click="closeModal()">Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="showLoadingScreen" class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen">
                <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
                    {{ loadingText }}
                </loading>
            </div>
        </div>

        <div
        class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
        :class="{
                'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,
            }"
        >
            <div class="px-10 py-5 bg-white rounded-lg ring-2 w-96">
                <div class="flex items-center text-blue-500 mt-2 mb-4 ml-2">{{ message }}</div>
                <div class="flex justify-center mt-2">
                    <button
                        @click="closeAll"
                        class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
import AppInput from "@/components/AppInput";
import Loading from "@/components/Loading";
export default {
    name: "pages.shortlisted-speakers.GenerateInvoice",
    components: {
        //AppLabel,
        AppInput,
        Loading
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        dealInfo: {
            type: Object,
            required: true,
        },
        shortListedSpeakersInfo: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        totalInstallmentAmount: 0,
        adminFee: null,
        recordSynced: 0,
        isDisabled: false,
        generatedRow: 2,
        showLoadingScreen: false,
        loadingText: 'Please Wait!',
        speakerProductId: '',
        poObj: {},
        productItems: []
    }),
    
    /* global ZOHO */
    methods: {    
        showPopUpMessage(message){
            this.showLoadingScreen = false;
            this.showPopUpModal = true;
            this.message = message;
            return false;
        },
        async generatePO() {
            this.loadingText = 'Generating PO...';
            this.showLoadingScreen = true;
            this.recordSynced = 0;
            const getProducts = await ZOHO.CRM.API.searchRecord({Entity:"Products",Type:"criteria",Query:"(Product_Code:equals:Speaker Fee)"});
            if (getProducts.data)
            {     
                getProducts.data.forEach(productDetails => {
                    if (productDetails.Product_Code == "Speaker Fee") 
                    {
                        this.speakerProductId = productDetails.id;
                    }
                    
                });
            } else {
                this.showPopUpMessage('No Speaker Fee product found');
                return false;
            }

            if (this.speakerProductId == '')
            {
                this.showPopUpMessage('No Speaker Fee product Found where product code is "Speaker Fee"');
                return false;
            }
       
            this.generatePOData();
              
        },
        async generatePOData() {
           
            this.recordSynced = 0;
            for await (const speakerDetails of this.shortListedSpeakersInfo) {
                this.poObj = {};
                let lineItems = {};
                //const element = speakerDetails;
                let listPrice = speakerDetails.listprice;
                let commision = speakerDetails.commission;
                let speakerName = speakerDetails.name;
                const vendorId = await this.getVendorId(speakerDetails);    
               
                if (vendorId) {
                    let record_number = this.recordSynced+1;
                    this.poObj['Subject'] = this.dealInfo.booking_number+' '+ this.dealInfo.client_name+ ' '+record_number;
                    this.poObj['Contact_Name'] = this.dealInfo.contactId;            
                    this.poObj['Opportunity'] = this.dealInfo.deal_id;
                    this.poObj['Status'] = 'Created';
                    this.poObj['Vendor_Name'] = vendorId;
                    this.poObj['PO_Date'] = new Date().toISOString().split('T')[0];
        
                    
                    if (this.dealInfo.saxton_or_csnz == 'Saxton')              
                    {
                        this.poObj['Add_To_Saxton_Xero'] = true;
                        lineItems['name'] = 'GST on Expenses';
                        lineItems['percentage'] = 10;
                        this.poObj['Saxton_Consultants'] = this.dealInfo.consultant;
                        this.poObj['Saxton_CSU'] = this.dealInfo.co_ordinator;
                        
                    }
                    
                    if (this.dealInfo.saxton_or_csnz == 'CSNZ')
                    {
                        this.poObj['Add_To_CSNZ_Xero'] = true;
                        lineItems['name'] = 'GST on Expenses CSNZ';
                        lineItems['percentage'] = 15;
                        this.poObj['CSNZ_Consultants'] = this.dealInfo.consultant; 
                        this.poObj['CSNZ_CSU'] = this.dealInfo.co_ordinator;
                    }
                
                    //Product_Items

                    this.productItems = [];
                
                    this.productItems.push(
                        {
                            "product": this.speakerProductId,
                            "product_description": this.dealInfo.booking_number+" \n"+speakerName,
                            "list_price": this.sumNetAmount(listPrice,commision),
                            "quantity": 1,
                            "Discount": 0,
                            "line_tax": [lineItems],
                            "total": this.sumNetAmount(listPrice,commision)
                        }
                    )
                    this.poObj['Product_Details'] = this.productItems;
                    
                    await this.sendPODataToAPI(this.poObj);
                }
                
                
            }
            
        },
        async getVendorId(element)
        {
    
            const getRecords = await ZOHO.CRM.API.getRelatedRecords({Entity:"Speakers",RecordID:element.id,RelatedList:"Vendors"});
              if (getRecords.data)  
              {
                  let speakerDetails = getRecords.data[0];
                  if (speakerDetails)
                  {
                    return speakerDetails.id;
                  }
                           
              } else {
                const recordDetails = await ZOHO.CRM.API.getRecord({Entity:"Speakers",RecordID:element.id});
            
                if (recordDetails.data)  
                {
                    let speakerDetails = recordDetails.data[0];
                    let vendorData = JSON.parse(JSON.stringify({'Vendor_Name':element.name,'Speaker':element.id,Email:speakerDetails.Email}));
                         
                    return await this.createPO(vendorData);
    
                } 
              }
        },
        createPO(vendorData)
        {
            return new Promise(resolve => {
                ZOHO.CRM.API.insertRecord({Entity:"Vendors",APIData:vendorData,Trigger:["workflow"]}).then((res) =>{
                    if (res.data)
                    {
                        return resolve(res.data[0].details.id);
                    }               
                });  
            });
        },
        async sendPODataToAPI(poObj) {
          
            let poData = JSON.parse(JSON.stringify(poObj));
            
            try{
                const createRecordResult = await ZOHO.CRM.API.insertRecord({Entity:"Purchase_Orders",APIData:poData,Trigger:["workflow"]});
                if (createRecordResult.data) {
                    let data = createRecordResult.data[0];
                    
                    if (data.code == "SUCCESS") {
                        this.recordSynced = this.recordSynced + 1;
                    }
                   
                    if(this.recordSynced == this.shortListedSpeakersInfo.length)
                    {
                        this.showLoadingScreen = false;
                        this.showPopUpModal = true;
                        this.message = 'Purchase Order is created Successfully!';
                        this.closeAll();
                    }
                

                }

                
            }catch (error) {
                console.error(error);
                this.showPopUpMessage('PO not created.Error Detail: '+ error.data[0].message);
                console.log('error', error);
                return false;
            }

            
        },
        sumNetAmount(list_price,commision){
            const pattern1 = /[$]/g;
            const pattern2 = /[%]/g;
            let amount = 0;
            if (commision != null && commision.search(pattern1) != -1 )
            {
                amount = Number(commision.replace('$','')); 
            } else if(commision != null && commision.search(pattern2) != -1) {
                commision = Number(commision.replace('%',''));
                amount = list_price * commision/100;
            } else {
                return amount;
            }
            return Number(list_price - amount);  
        },
        closeModal() {
            this.$emit('update:isVisible', false)
        },
        closeAll(){
            setTimeout(() => {
                this.showPopUpModal = false;
                this.closeModal(); 
                window.parent.location = document.referrer+"crm/org749100371/tab/Potentials/"+this.dealInfo.deal_id;   
            }, 2000); 
        }
    }

}
</script>

<style scoped>

</style>

