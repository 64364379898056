<template>
    <div>
        <textarea
            :class="`${bgColorClass} rounded-lg appearance-none border-2 ${borderColorClass} ${focusClass} rounded w-full ${paddingClass} ${fgColorClass} leading-tight`"
            :value="value"
            @keyup="$emit('update:value', $event.target.value)"
            :disabled="disabled"
            :placeholder="placeholder"
            @click="selectAtClick ? $event.target.select() : null"
            :rows="rows"
        >
        </textarea>
    </div>
</template>

<script>
export default {
    name: 'components.AppTextarea',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectAtClick: {
            type: Boolean,
            required: false,
            default: false,
        },
        bgColorClass: {
            type: String,
            required: false,
            default: 'bg-white',
        },
        fgColorClass: {
            type: String,
            required: false,
            default: 'text-gray-700',
        },
        borderColorClass: {
            type: String,
            required: false,
            default: 'border-gray-200',
        },
        focusClass: {
            type: String,
            required: false,
            default: 'focus:outline-none focus:ring-2 focus:ring-gray-400',
        },
        paddingClass: {
            type: String,
            required: false,
            default: 'py-2 px-4',
        },
        rows: {
            type: Number,
            required: false,
            default: 3,
        },
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>
