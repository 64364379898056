<template>
  <!-- component -->
  <!-- This is an example component -->
  <div class="relative mx-4 mt-4">
    <div class="flex">
      <div class="basis-1/4">
        <div class="flex gap-x-8">
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
            @click.prevent="processGenerateInvoice"
            v-show="isAnySpeakerBooked"
          >
            Generate Invoices
          </button>
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded text-center whitespace-nowrap"
            @click.prevent="processGeneratePO"
            v-show="isAnySpeakerBooked"
          >
            Generate PO
          </button>
        </div>
      </div>
      <div class="basis-1/4"></div>
      <div class="basis-1/2">
        <div class="grid justify-items-end">
          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click.prevent="openFilterShortlistedSpeakers = true"
          >
            Search Speakers
          </button>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="flex mt-4">
        <table
          class="table-auto w-72 overflow-x-auto border-2 text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3 text-center">No</th>
              <th scope="col" class="text-center px-6 py-3">Action</th>
              <th scope="col" class="px-6 py-3 text-center">Speaker Name</th>
              <th scope="col" class="px-6 py-3 text-center">Shortlist</th>
              <th scope="col" class="px-6 py-3 text-center w-40">Status</th>
              <th scope="col" class="px-6 py-3 text-center"></th>
              <th scope="col" class="px-6 py-3 text-center">Commission</th>
              <th scope="col" class="px-6 py-3 text-center">Exclusivity</th>
              <th scope="col" class="px-6 py-3 text-center">
                Live Keynote Fee
              </th>
              <th scope="col" class="px-6 py-3">
                Virtual Keynote Fee (Livestream)
              </th>
              <th scope="col" class="px-6 py-3">
                Virtual Keynote Fee (Pre recorded)
              </th>
              <th scope="col" class="px-6 py-3">Full Day Workshop Fee</th>
              <th scope="col" class="px-6 py-3">Half Day Workshop Fee</th>
              <th scope="col" class="px-6 py-3">MC Half Day Fee</th>
              <th scope="col" class="px-6 py-3">MC Full Day Fee</th>
              <th scope="col" class="px-6 py-3">Education/NFP Fee</th>
              <th scope="col" class="px-6 py-3">Campaign Fee</th>
              <th scope="col" class="px-6 py-3">Other Fee</th>
              <th scope="col" class="px-6 py-3">Account Name</th>
              <th scope="col" class="px-6 py-3">Contact Name</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="shortlistedSpeakerList.length > 0">
              <tr
                v-for="(speaker, index) in shortlistedSpeakerList"
                :key="index"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="px-6 py-4">
                  {{ index + 1 }}
                </td>
                <td class="px-6 py-4">
                  <div class="flex gap-x-4">
                    <div class="flex-auto py-1">
                      <a
                        :href="
                          'https://crm.zoho.com/crm/org749100371/tab/LinkingModule5/' +
                          speaker.shortlisted_speaker_crm_id +
                          '/edit'
                        "
                        target="_blank"
                        v-show="speaker.shortlisted_speaker_crm_id"
                      >
                        <!-- <font-awesome-icon icon="fa-solid fa-pen" /> -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                        >
                          <path
                            d="M14.5 5.5L3 17 3 21 7 21 18.5 9.5zM21.2 2.8c-1.1-1.1-2.9-1.1-4 0L16 4l4 4 1.2-1.2C22.3 5.7 22.3 3.9 21.2 2.8z"
                          />
                        </svg>
                      </a>
                    </div>
                    <div class="flex-auto">
                      <button
                        :class="[
                          'px-4 py-1 text-gray-700 ring-1 ring-inset ring-gray-700 rounded-md',
                          speaker.file_generated == true
                            ? 'bg-green-500'
                            : 'bg-white',
                        ]"
                        @click="generateFile(speaker)"
                        v-if="speaker.status == 'Booked'"
                      >
                        {{
                          speaker.file_generated == true
                            ? "Generated"
                            : "Generate"
                        }}
                      </button>
                    </div>
                  </div>
                </td>
                <th
                  scope="row"
                  class="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  <a :href="'https://crm.zoho.com/crm/org749100371/tab/CustomModule4/' +speaker.speaker.crm_id"
                        class="text-blue-500"
                        target="_blank"
                        @mouseover="onHover(speaker.speaker.crm_id)"
                        >{{ speaker.speaker.speaker_name }}
                      </a>
                  <!-- <el-popover
                    placement="right"
                    :width="500"
                    trigger="hover"
                    :show-after="500"
                  >
                    <template #reference>
                      <a
                        :href="
                          'https://crm.zoho.com/crm/org749100371/tab/CustomModule4/' +
                          speaker.speaker.crm_id
                        "
                        class="text-blue-500"
                        target="_blank"
                        @mouseover="onHover(speaker.speaker.crm_id)"
                        >{{ speaker.speaker.speaker_name }}
                      </a>
                    </template>
                    <el-descriptions
                      :title="speaker.speaker.speaker_name"
                      :column="1"
                      :size="size"
                      direction="horizontal"
                      :style="blockMargin"
                    >
                      <el-descriptions-item label="Live Keynote Fee">{{
                        speakerFeeData.Live_Keynote_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item
                        label="Virtual Keynote Fee (Livestream)"
                        >{{
                          speakerFeeData.Virtual_Keynote_Livestream_Fee
                        }}</el-descriptions-item
                      >
                      <el-descriptions-item
                        label="Virtual Keynote Fee (Pre recorded)"
                        >{{
                          speakerFeeData.Virtual_Keynote_Pre_recorded_Fee
                        }}</el-descriptions-item
                      >
                      <el-descriptions-item label="Full Day Workshop Fee">{{
                        speakerFeeData.Live_Workshop_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Half Day Workshop Fee">{{
                        speakerFeeData.Half_Day_Workshop_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="MC Half Day Fee">{{
                        speakerFeeData.MC_Rate
                      }}</el-descriptions-item>
                      <el-descriptions-item label="MC Full Day Fee">{{
                        speakerFeeData.MC_Full_Day
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Campaign Fee">{{
                        speakerFeeData.Campaign_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Other Fee">{{
                        speakerFeeData.Other_Fee
                      }}</el-descriptions-item>
                      <el-descriptions-item label="Gross Fees Range">{{
                        speakerFeeData.Gross_Fees_Range
                      }}</el-descriptions-item>
                      <el-descriptions-item label="% Commission">{{
                        speakerFeeData.Gross_Fees_for_Dues
                      }}</el-descriptions-item>
                    </el-descriptions>
                  </el-popover> -->
                </th>
                <th
                  scope="row"
                  class="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  <a
                    :href="
                      'https://crm.zoho.com/crm/org749100371/tab/LinkingModule5/' +
                      speaker.shortlisted_speaker_crm_id
                    "
                    class="text-blue-500"
                    target="_blank"
                    v-show="speaker.shortlisted_speaker_name"
                    >{{ speaker.shortlisted_speaker_name }}
                  </a>
                </th>
                <td class="px-6 py-4">
                  <div class="relative text-center whitespace-nowrap w-32">
                    <select
                      @change="(event) => selectOption(event, speaker)"
                      :disabled="
                        speaker.status == 'Booked' ? 'disabled' : false
                      "
                      :class="[
                        'block appearance-none w-full border border-gray-200 text-gray-700 py-1 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
                        'status-' + speaker.speaker.crm_id,
                      ]"
                      id="grid-state"
                    >
                      <option
                        v-for="(option, index) in statusOptions"
                        :selected="
                          speaker.status == option.label ? 'selected' : null
                        "
                        :value="option.id"
                        :key="index"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4">
                  <span :class="changeColor(speaker.status)">
                    {{ speaker.status }}
                  </span>
                </td>

                <td class="px-6 py-4">
                  <div
                    class="flex gap-x-2"
                    v-if="speaker.shortlisted_speaker_crm_id"
                  >
                    <div class="flex-auto w-28">
                      <jet-input
                        :style="`px-1 py-2 text-xs`"
                        @keyup="getCommission"
                        :id="'input-' + speaker.shortlisted_speaker_crm_id"
                        class="py-2 px-2 rounded"
                        :value="
                          getSSCommission(speaker.shortlisted_speaker_crm_id)
                        "
                      />
                    </div>
                    <div
                      class="flex-auto py-2"
                      :class="[
                        'showTick-' + speaker.shortlisted_speaker_crm_id,
                      ]"
                      hidden
                    >
                      <a
                        @click="
                          updateCommission(speaker.shortlisted_speaker_crm_id)
                        "
                        href="javascript:void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24px"
                          height="24px"
                        >
                          <path
                            d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"
                          />
                        </svg>
                        <!-- <font-awesome-icon icon="fa-solid fa-check"
                      /> -->
                      </a>
                    </div>
                  </div>
                </td>

                <td class="text-center px-6 py-4">
                  {{ speaker.speaker.exclusivity }}
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Live_Keynote_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Virtual_Keynote_Fee_Livestream"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Virtual_Keynote_Fee_Pre_recorded"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Full_Day_Workshop_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Half_Day_Workshop_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "MC_Half_Day_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "MC_Full_Day_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Education_NFP_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Campaign_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  <div v-if="speaker.shortlisted_speaker_crm_id">
                    {{
                      getFeeAmount(
                        speaker.shortlisted_speaker_crm_id,
                        "Other_Fee"
                      )
                    }}
                  </div>
                </td>
                <td class="text-center px-6 py-4">
                  {{ speaker.speaker.account_name }}
                </td>
                <td class="text-center px-6 py-4">
                  {{ speaker.contact_name }}
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="9" class="text-center text-gray-700">
                No Data Available!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- {{ shortlistedSpeakerList }} -->
    </div>
    <GenerateInvoice
      :dealInfo="dealInfo"
      :shortlistedSpeakerList="shortlistedSpeakerList"
      :shortListedSpeakersInfo="shortListedSpeakersInfo"
      :calculatedBill="calculatedBill"
      v-model:invoiceLoaded="invoiceLoaded"
      v-model:is-visible="openGenerateInvoice"
    />
    <GeneratePO
      :dealInfo="dealInfo"
      :shortListedSpeakersInfo="shortListedSpeakersInfo"
      v-model:is-visible="openGeneratePO"
    />
    <FilterShortlistedSpeakers
      :dealInfo="dealInfo"
      v-model:is-visible="openFilterShortlistedSpeakers"
    />
    <GenerateFile
      :dealInfo="dealInfo"
      v-model:is-visible="openGenerateFileModal"
    />
    <div
      v-if="showLoadingScreen"
      class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-slate-300"
    >
      <loading>Please Wait...</loading>
    </div>
    <div
      v-if="loading"
      class="fixed top-0 left-0 flex items-center justify-center w-screen h-screen"
    >
      <loading paddingClass="px-3 py-1" bgColorClass="bg-gray-400">
        {{ loadingText }}
      </loading>
    </div>
  </div>

  <div
    class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !showPopUpModal,
    }"
  >
    <div class="px-10 py-5 bg-white rounded-lg ring-2 w-auto">
      <div
        class="flex items-center text-blue-500 mt-2 mb-4 ml-2"
        style="white-space: pre"
      >
        {{ message }}
      </div>
      <div class="flex justify-center mt-2">
        <button
          @click="showPopUpModal = false"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-gray-500 border-2 border-green-500 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
  <div
    class="fixed z-10 top-0 left-0 flex items-center justify-center w-screen h-screen bg-gray-600/50 transition-all ease-in duration-75"
    :class="{
      'opacity-0 invisible pointer-events-none scale-75': !showHeldPopUpModal,
    }"
  >
    <div class="px-10 py-5 bg-white rounded-lg ring-2 w-100">
      <div class="grid grid-cols-2 gap-4">
        <div class="items-end text-blue-500 pt-2">Hold Date:</div>
        <div class="items-center">
          <date-time-input
            v-model="heldDateValue"
            :enable-time-picker="false"
            teleport-center
            format="dd/MM/yyyy"
          ></date-time-input>
        </div>

        <div class="items-end text-blue-500 pt-2">Hold End Date:</div>
        <div class="items-center">
          <date-time-input
            v-model="heldEndDateValue"
            :enable-time-picker="false"
            teleport-center
            format="dd/MM/yyyy"
          ></date-time-input>
        </div>
      </div>

      <div
        class="items-start text-red-500 mt-2 mb-4 ml-2 py-4"
        v-if="notDateFound"
      >
        <span>Please select a hold End date.</span>
      </div>

      <div class="flex justify-center mt-2 gap-4 py-4">
        <button
          @click="updateStatus"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-blue-500 border-2 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Update
        </button>
        <button
          @click="cancelUpdateStatus"
          class="w-1/4 px-4 text-sm font-semibold text-white bg-red-500 border-2 shadow leading-6 rounded-md transition ease-in-out duration-150"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import AppLabel from "@/components/AppLabel";
import GenerateInvoice from "@/views/Shortlisted-Speakers/GenerateInvoice";
import GeneratePO from "@/views/Shortlisted-Speakers/GeneratePO";
import FilterShortlistedSpeakers from "@/views/Shortlisted-Speakers/FilterShortlistedSpeakers";
import {
  fetchShortlistedSpeakers,
  updateShortlistedSpeaker,
  generateFile,
} from "@/services/ShortlistedSpeakerService";
import { getLocalISOTime } from "@/utils";
import JetInput from "@/components/JetInput";
import Loading from "@/components/Loading";
import GenerateFile from "@/components/GenerateFile";
import DateTimeInput from "@/components/DateTimePicker.vue";

export default {
  name: "pages.shortlisted-speakers.widget",
  components: {
    GenerateInvoice,
    GeneratePO,
    FilterShortlistedSpeakers,
    JetInput,
    Loading,
    GenerateFile,
    DateTimeInput,
  },
  data: () => ({
    openFilterShortlistedSpeakers: false,
    openGenerateInvoice: false,
    openGeneratePO: false,
    isAnySpeakerBooked: false,
    showHeldPopUpModal: false,
    heldEndDateValue: null,
    heldDateValue: null,
    invoiceLoaded: false,
    updateStatusData: {},
    notDateFound: false,
    dealInfo: {
      deal_id: null,
      name: null,
      function_date: null,
      client_name: null,
      booking_number: null,
      acc_id: null,
      contactId: null,
    },
    emails: [],
    testing: null,
    again: null,
    shortlistedSpeakerList: [],
    shortListedSpeakersInfo: [],
    calculatedBill: 0,
    statusOptions: [
      { id: "-None-", label: "-None-" },
      { id: "Held", label: "Held" },
      { id: "Booked", label: "Booked" },
      { id: "Released", label: "Released" },
      { id: "Postponed", label: "Postponed" },
    ],
    selectedValue: null,
    loadingText: "Please Wait...",
    showLoadingScreen: false,
    openGenerateFileModal: false,
    loading: false,
    showPopUpModal: false,
    message: null,
    commission: null,
    showTick: false,
    speakerFeeData: {},
    blockMargin: "32px",
  }),
  async mounted() {
    await this.init();
    document.querySelector(".home-menu");
  },

  /* global ZOHO */
  methods: {
    async init() {
      this.showLoadingScreen = true;
      ZOHO.embeddedApp.on("PageLoad", async (data) => {
        // let entityId = data.EntityId
        await ZOHO.CRM.API.getRecord({
          Entity: data.Entity,
          RecordID: data.EntityId,
        }).then((response) => {
          let data = response.data[0];
          this.updatedDealFields(data);
          if (this.dealInfo.client_name === undefined) {
            this.showPopUpNotification("Contact Not Assigned");
            return false;
          }
          this.fetchShortlistedSpeaker(data.id);
          this.shortListedspeakersBill(data.id);
        });
      });
      ZOHO.embeddedApp.init();
      this.loading = false;
    },
    async processGenerateInvoice() {
      if (this.dealInfo.deal_id) {
        this.showLoadingScreen = true;
        const recordDetails = await ZOHO.CRM.API.getRecord({
          Entity: "Deals",
          RecordID: this.dealInfo.deal_id,
        });
        let dealInfo = recordDetails.data[0];
        let error_notification = "";

        if (dealInfo.Invoice_Status == "Created") {
          this.showLoadingScreen = false;
          this.showPopUpNotification();
          return false;
        }

        if (dealInfo.Function_Date == null) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(
            "Function Date is null. Please set a function date."
          );
          return false;
        }
        this.updatedDealFields(dealInfo);
        await this.shortListedspeakersBill(this.dealInfo.deal_id);
        const noAmountSpeakerList = this.shortListedSpeakersInfo.filter(
          (speaker) => speaker.listprice == 0
        );
        noAmountSpeakerList.forEach((speaker_details) => {
          error_notification += speaker_details.name + "'s amount is empty. \n";
        });
        if (error_notification) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(error_notification);
          return false;
        }
        this.invoiceLoaded = false;
        this.openGenerateInvoice = true;
      }
    },
    updatedDealFields(data) {
      this.dealInfo.deal_id = data.id;
      this.dealInfo.name = data.Deal_Name;
      this.dealInfo.function_date = data.Function_Date
        ? data.Function_Date
        : getLocalISOTime(new Date().toISOString());
      this.dealInfo.client_name = data.Contact_Name?.name;
      this.dealInfo.contactId = data.Contact_Name?.id;
      this.dealInfo.booking_number = data.Enquiry_Booking_Number;
      this.dealInfo.acc_id = data.Account_Name?.id;
      this.dealInfo.inv_status = data.Invoice_Status;
      this.dealInfo.saxton_or_csnz = data.Saxton_or_CSNZ;
      this.dealInfo.consultant = data.Consultant?.name;
      this.dealInfo.co_ordinator = data.Co_Ordinator?.name;
    },
    async processGeneratePO() {
      if (this.dealInfo.deal_id) {
        this.showLoadingScreen = true;
        const recordDetails = await ZOHO.CRM.API.getRecord({
          Entity: "Deals",
          RecordID: this.dealInfo.deal_id,
        });
        let dealInfo = recordDetails.data[0];
        let error_notification = "";

        if (dealInfo.Invoice_Status != "Created") {
          this.showLoadingScreen = false;
          this.showPopUpNotification("Invoice is not created.");
          return false;
        }

        this.updatedDealFields(dealInfo);

        await this.shortListedspeakersBill(this.dealInfo.deal_id);

        const noAmountSpeakerList = this.shortListedSpeakersInfo.filter(
          (speaker) => speaker.listprice == 0 || speaker.commission == null
        );
        noAmountSpeakerList.forEach((speaker_details) => {
          error_notification +=
            speaker_details.name + "'s amount/commission is empty. \n";
        });
        if (error_notification) {
          this.showLoadingScreen = false;
          this.showPopUpNotification(error_notification);
          return false;
        }
        this.openGeneratePO = true;
      }
    },
    showPopUpNotification(
      msg = "Invoice already created for this Opportunity"
    ) {
      this.message = msg;
      this.showPopUpModal = true;
      this.openGenerateInvoice = false;
    },
    async shortListedspeakersBill(dealId) {
      await ZOHO.CRM.API.getRelatedRecords({
        Entity: "Deals",
        RecordID: dealId,
        RelatedList: "Speakers5",
      }).then((response) => {
        if (response.status != 204) {
          let shortListedSp = response.data;
          const calBill = this.calulateSpeakerBill(shortListedSp);
          this.calculatedBill = !isNaN(Number(calBill)) ? calBill : 0;
          this.showLoadingScreen = false;
        } else {
          this.showLoadingScreen = false;
        }
      });
    },
    fetchShortlistedSpeaker(dealId) {
      fetchShortlistedSpeakers(dealId, this.dealInfo.function_date)
        .then((res) => {
          console.log(res.data);
          this.shortlistedSpeakerList = res.data;
          //console.log(res.data);
          if (
            this.shortlistedSpeakerList.find(
              (speaker) => speaker.status === "Booked"
            )
          ) {
            this.isAnySpeakerBooked = true;
          }
          //this.showLoadingScreen = false
        })
        .catch((err) => console.log(err));
    },
    calulateSpeakerBill(shortListedSp) {
      let calBill = 0;
      this.shortListedSpeakersInfo = [];
      shortListedSp.forEach((slDetails) => {
        if (slDetails.Status == "Booked") {
          console.log('slDetails',slDetails);
          let speakerDetails = {
            name: slDetails.Shortlisted_Speakers.name,
            id: slDetails.Shortlisted_Speakers.id,
            listprice: 0,
            commission: slDetails.Commission,
            shortlistedSpId: slDetails.id,
            Live_Keynote_Fee: null,
            Virtual_Keynote_Fee_Livestream: null,
            Virtual_Keynote_Fee_Pre_recorded: null,
            Full_Day_Workshop_Fee: null,
            Half_Day_Workshop_Fee: null,
            MC_Half_Day_Fee: null,
            MC_Full_Day_Fee: null,
            Education_NFP_Fee: null,
            Campaign_Fee: null,
            Other_Fee: null,
          };

          //Allow ENF to Qwilr
          if (slDetails.Allow_LKF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Live_Keynote_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Live_Keynote_Fee);
            speakerDetails["Live_Keynote_Fee"] = Number(
              slDetails.Live_Keynote_Fee
            );
            //console.log(calBill);
          }

          //Allow VKFL to Qwilr
          if (slDetails.Allow_VKFL_to_Qwilr == true) {
            calBill =
              calBill + Number(slDetails.Virtual_Keynote_Fee_Livestream);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Virtual_Keynote_Fee_Livestream);
            speakerDetails["Virtual_Keynote_Fee_Livestream"] = Number(
              slDetails.Virtual_Keynote_Fee_Livestream
            );
            //console.log(calBill);
          }

          //Allow VKFP to Qwilr

          if (slDetails.Allow_VKFP_to_Qwilr == true) {
            calBill =
              calBill + Number(slDetails.Virtual_Keynote_Fee_Pre_recorded);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Virtual_Keynote_Fee_Pre_recorded);
            speakerDetails["Virtual_Keynote_Fee_Pre_recorded"] = Number(
              slDetails.Virtual_Keynote_Fee_Pre_recorded
            );
            //console.log(calBill);
          }

          //Allow FDWF to Qwilr

          if (slDetails.Allow_FDWF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Full_Day_Workshop_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Full_Day_Workshop_Fee);
            speakerDetails["Full_Day_Workshop_Fee"] = Number(
              slDetails.Full_Day_Workshop_Fee
            );
            //console.log(calBill);
          }

          //Allow HDWF to Qwilr

          if (slDetails.Allow_HDWF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Half_Day_Workshop_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] +
              Number(slDetails.Half_Day_Workshop_Fee);
            speakerDetails["Half_Day_Workshop_Fee"] = Number(
              slDetails.Half_Day_Workshop_Fee
            );
            //console.log(calBill);
          }

          //Allow MCHDF to Qwilr

          if (slDetails.Allow_MCHDF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.MC_Half_Day_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Half_Day_Fee);
            speakerDetails["MC_Half_Day_Fee"] = Number(
              slDetails.MC_Half_Day_Fee
            );

            //console.log(calBill);
          }

          //Allow MCFDF to Qwilr

          if (slDetails.Allow_MCFDF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.MC_Full_Day_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.MC_Full_Day_Fee);
            speakerDetails["MC_Full_Day_Fee"] = Number(
              slDetails.MC_Full_Day_Fee
            );
            //console.log(calBill);
          }

          //Allow ENF to Qwilr

          if (slDetails.Allow_ENF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Education_NFP_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Education_NFP_Fee);
            speakerDetails["Education_NFP_Fee"] = Number(
              slDetails.Education_NFP_Fee
            );
            //console.log(calBill);
          }

          //Allow Campaign Fee to Qwilr

          if (slDetails.Allow_CF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Campaign_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Campaign_Fee);
            speakerDetails["Campaign_Fee"] = Number(slDetails.Campaign_Fee);
            //console.log(calBill);
          }

          //Allow Other Fee to Qwilr

          if (slDetails.Allow_OF_to_Qwilr == true) {
            calBill = calBill + Number(slDetails.Other_Fee);
            speakerDetails["listprice"] =
              speakerDetails["listprice"] + Number(slDetails.Other_Fee);
            speakerDetails["Other_Fee"] = Number(slDetails.Other_Fee);
            //console.log(calBill);
          }

          this.shortListedSpeakersInfo.push(speakerDetails);
        }
      });

      return calBill;
    },
    selectOption(event, speaker) {
      let eventLogName =
        speaker.speaker.speaker_name + " Event Logistics " + this.dealInfo.name;
      let data = {
        status: event.target.value,
        shortlisted_speaker_crm_id: speaker.shortlisted_speaker_crm_id,
        opportunity_id: this.dealInfo.deal_id,
        eventLogisticName: eventLogName,
        speaker_crm_id: speaker.speaker.crm_id,
        speaker_id: speaker.id,
        hold_date: null,
        hold_end_date: null,
      };

      if (event.target.value != speaker.status && speaker.status != "Booked") {
        if (event.target.value == "Held") {
          this.heldDateValue = null;
          this.heldEndDateValue = null;
          this.showHeldPopUpModal = true;
          this.updateStatusData = data;
        } else {
          this.loadingText = "Updating Status...";
          this.loading = true;
          updateShortlistedSpeaker(speaker.id, data)
            .then((res) => {
              if (res) {
                this.loading = false;
                speaker.status = event.target.value;
              }
            })
            .catch((err) => console.log(err));
        }
      }
    },
    updateStatus() {
      if (this.heldEndDateValue != null) {
        this.showHeldPopUpModal = false;
        this.notDateFound = false;
        this.loadingText = "Updating Status...";
        this.loading = true;
        this.updateStatusData["hold_date"] =
          this.heldDateValue != null
            ? new Date(this.heldDateValue).toISOString().split("T")[0] +
              "T00:00:00+11:00"
            : new Date().toISOString().split("T")[0] + "T00:00:00+11:00";
        this.updateStatusData["hold_end_date"] =
          new Date(this.heldEndDateValue).toISOString().split("T")[0] +
          "T00:00:00+11:00";

        updateShortlistedSpeaker(
          this.updateStatusData.speaker_id,
          this.updateStatusData
        )
          .then((res) => {
            if (res) {
              this.loading = false;
              let status = document.querySelector(
                ".status-" + this.updateStatusData.speaker_crm_id
              );
              status.value = this.updateStatusData.status;

              this.fetchShortlistedSpeaker(this.dealInfo.deal_id);
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.notDateFound = true;
      }
    },
    cancelUpdateStatus() {
      let status = document.querySelector(
        ".status-" + this.updateStatusData.speaker_crm_id
      );
      status.value = "";
      (this.heldEndDateValue = null),
        (this.heldDateValue = null),
        (this.showHeldPopUpModal = false);
      this.updateStatusData = {};
      this.notDateFound = false;
    },
    async generateFile(speaker) {
      this.showLoadingScreen = true;
      let opportunity_id = this.dealInfo.deal_id;
      let speaker_id = speaker.speaker.crm_id;
      let shortlistedSpId = speaker.shortlisted_speaker_crm_id;
      //let url = 'https://www.zohoapis.com/crm/v2/functions/attachmentsforshortlistedspeakers/actions/execute?auth_type=apikey&zapikey=1003.eb3e478f86bf2a0d3b886841527f6a2d.6012da41bf5a78c0c61e4e93abcd3d3a'
      // let arg = {"opportunity_id": "4874513000004338144","speaker_id":"4874513000003916062"}
      let arg = { opportunity_id: opportunity_id, speaker_id: speaker_id };

      let data = {
        file_generated: true,
      };

      // let formData = new FormData();
      // formData.append('arguments', arg)''
      try {
        const res = await generateFile(arg);
        if (res.data.status == "success") {
          const update_res = await updateShortlistedSpeaker(
            shortlistedSpId,
            data
          );
          if (update_res) {
            this.showLoadingScreen = false;
            this.message =
              "Your document is being generated. Please refresh the window after few minutes to see the document in the attachments";
            this.showPopUpModal = true;
          }
        } else {
          this.showLoadingScreen = false;
          this.data.message = res.data.message;
          this.showPopUpModal = true;
        }
      } catch (err) {
        this.showLoadingScreen = false;
        this.showPopUpModal = true;
        this.message =
          "File is not generated sucessfully.Error Details: " + err;
        console.log(err);
      }
    },
    changeColor(status) {
      let color;
      switch (status) {
        case "Held":
          color = "text-orange-500";
          break;
        case "Booked":
          color = "text-green-500";
          break;
        case "Released":
          color = "text-red-500";
          break;
        case "Postponed":
          color = "text-yellow-500";
          break;
        default:
          color = "text-gray-200";
      }
      return color;
    },
    async updateCommission(sp_id) {
      let commission = document.getElementById("input-" + sp_id);
      if (confirm("Are you sure you want to update this commission?")) {
        this.showLoadingScreen = true;
        this.loadingText = "Updating Please Wait..";
        var data = {
          Entity: "Opportunities_X_Speakers",
          APIData: {
            id: sp_id,
            Commission: commission.value,
          },
          Trigger: ["workflow"],
        };
        const update_res = await ZOHO.CRM.API.updateRecord(data);
        if (update_res) {
          this.showLoadingScreen = false;
          this.message = "Commission updated Successfully!";
          this.showPopUpModal = true;
        }
      } else {
        commission.value = this.getSSCommission(sp_id);
        //commission_evt.input.value = '';
      }

      const showTick = document.querySelector(".showTick-" + sp_id);
      showTick.style.display = "none";
    },
    getCommission(event) {
      const inputEvt = event.currentTarget;
      let spId = inputEvt.id.split("-")[1];
      let commissionValue = event.target.value;
      let oldValue = this.getSSCommission(spId);
      const showTick = document.querySelector(".showTick-" + spId);
      if (commissionValue == oldValue) {
        showTick.style.display = "none";
      } else {
        showTick.style.display = "block";
      }
    },
    getSSCommission(sp_id) {
      let com = this.shortListedSpeakersInfo.filter(
        (ssp) => ssp.shortlistedSpId == sp_id
      );
      if (com.length > 0) {
        return com[0].commission;
      }
      return 0;
    },
    getFeeAmount(ssp_id, api_name) {
      let spd = this.shortListedSpeakersInfo.filter(
        (ssp) => ssp.shortlistedSpId == ssp_id
      );
      if (spd.length > 0) {
        return spd[0][api_name] != null ? spd[0][api_name] : 0;
      }
      return 0;
    },
    adjustDays(date, days, operation) {
      var result = new Date(date);
      if (operation == "Add") {
        result.setDate(result.getDate() + days);
      }

      if (operation == "Subtract") {
        result.setDate(result.getDate() - days);
      }
      return result;
    },
    async onHover(speakerId) {
      this.speakerFeeData = {};
      const recordDetails = await ZOHO.CRM.API.getRecord({
        Entity: "Speakers",
        RecordID: speakerId,
      });
      if (recordDetails.data) {
        let speakerDetails = recordDetails.data[0];
        this.speakerFeeData["Campaign_Fee"] = speakerDetails.Campaign_Fee;
        this.speakerFeeData["Live_Workshop_Fee"] =
          speakerDetails.Live_Workshop_Fee;
        this.speakerFeeData["Half_Day_Workshop_Fee"] =
          speakerDetails.Half_Day_Workshop_Fee;
        this.speakerFeeData["Live_Keynote_Fee"] =
          speakerDetails.Live_Keynote_Fee;
        this.speakerFeeData["Other_Fee"] = speakerDetails.Other_Fee;
        this.speakerFeeData["Virtual_Keynote_Livestream_Fee"] =
          speakerDetails.Virtual_Keynote_Livestream_Fee;
        this.speakerFeeData["Virtual_Keynote_Pre_recorded_Fee"] =
          speakerDetails.Virtual_Keynote_Pre_recorded_Fee;
        this.speakerFeeData["MC_Rate"] = speakerDetails.MC_Rate;
        this.speakerFeeData["MC_Full_Day"] = speakerDetails.MC_Full_Day;
        this.speakerFeeData["Gross_Fees_for_Dues"] =
          speakerDetails.Gross_Fees_for_Dues;
        this.speakerFeeData["Gross_Fees_Range"] =
          speakerDetails.Gross_Fees_Range;
      }
    },
  },
};
</script>

<style scoped></style>
